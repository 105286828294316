import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import useLocalI18n from 'Hooks/LocalI18n'
import ReactHtmlParser from 'react-html-parser'
import Button from 'Elements/Button'

declare const window: Window & {
  googleTranslateElementInit?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  google?: any
}

const getLocale = () => {
  const defaultLocale = 'en'
  let locale = navigator.language?.split('-')[0] || defaultLocale

  if (!document.cookie.includes('googtrans')) {
    document.cookie = `googtrans=/auto/${defaultLocale}; path=/; secure=true; sameSite:lax;`
  } else {
    locale =
      document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith(`googtrans=`))
        ?.split('=')[1]
        ?.replace(/\/auto\//, '') ?? defaultLocale
  }

  return { defaultLocale, locale }
}

export default function GoogleTranslate() {
  const { I18n } = useLocalI18n('elements/GoogleTranslate/Lang')
  const popoverRef = useRef<HTMLDivElement | null>(null)
  const elementRef = useRef<HTMLDivElement | null>(null)
  const googleTranslate = useRef()
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const doOnce = useRef(false)
  const [showMessage, setShowMessage] = useState(true)

  const loadScript = () => {
    if (scriptLoaded) return

    setShowMessage(false)

    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.src =
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    script.async = true

    document.body.appendChild(script)
  }

  const show = () => {
    popoverRef.current?.showPopover()

    window?.Beacon?.('close')
  }

  const close = () => {
    popoverRef.current?.hidePopover()
  }

  useEffect(() => {
    if (doOnce.current) return
    doOnce.current = true

    window.googleTranslateElementInit = () => {
      setScriptLoaded(true)

      googleTranslate.current = new window.google.translate.TranslateElement(
        {
          pageLanguage: 'auto',
          layout:
            window.google.translate.TranslateElement.InlineLayout.VERTICAL,
          includedLanguages:
            'en,es,fr,de,it,pt,nl,ru,zh-CN,zh-TW,ja,ko,hi,tr,sv,no,da,fi,el,cs,hu,th,vi,ms,sw'
        },
        elementRef.current
      )
    }

    const { defaultLocale, locale } = getLocale()

    if (locale !== defaultLocale) {
      loadScript()
    }
  }, [])

  return (
    <>
      <button
        className={styles.button}
        onClick={show}
        type="button"
        aria-label={I18n.t('google_translate.button')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24">
          <path
            d="M21 4H11l-1-3H3c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h8l1 3h9c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2M7 16c-2.76 0-5-2.24-5-5s2.24-5 5-5c1.35 0 2.48.5 3.35 1.3L9.03 8.57c-.38-.36-1.04-.78-2.03-.78-1.74 0-3.15 1.44-3.15 3.21S5.26 14.21 7 14.21c2.01 0 2.84-1.44 2.92-2.41H7v-1.71h4.68c.07.31.12.61.12 1.02C11.8 13.97 9.89 16 7 16m6.17-5.42h3.7c-.43 1.25-1.11 2.43-2.05 3.47-.31-.35-.6-.72-.86-1.1zm8.33 9.92c0 .55-.45 1-1 1H14l2-2.5-1.04-3.1 3.1 3.1.92-.92-3.3-3.25.02-.02c1.13-1.25 1.93-2.69 2.4-4.22H20v-1.3h-4.53V8h-1.29v1.29h-1.44L11.46 5.5h9.04c.55 0 1 .45 1 1z"
            fill="currentColor"></path>
        </svg>
      </button>
      <div
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line react/no-unknown-property
        popover="auto"
        id="google-translate-widget"
        className={styles.popover}
        ref={popoverRef}>
        <button
          autoFocus
          className={styles.close}
          type="button"
          aria-label={I18n.t('google_translate.close')}
          onClick={close}>
          <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            width="24"
            height="24"
            className="icon-cross">
            <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
          </svg>
        </button>
        <div ref={elementRef} className={styles.element} />
        {!showMessage && (
          <div className={styles.resetContainer}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                document.cookie = `googtrans=;path=/;max-age=0;`
                document.cookie = `googtrans=;path=/;max-age=0;domain=.giki.earth`
                window.location.reload()
              }}>
              {I18n.t('google_translate.reset')}
            </a>
          </div>
        )}
        {showMessage && (
          <div className={styles.text}>
            {ReactHtmlParser(I18n.t('google_translate.text'))}
            <div className={styles.buttons}>
              <Button
                small
                label={I18n.t('google_translate.accept')}
                onClick={loadScript}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
