import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

interface ListItemProps {
  children?: React.ReactNode | React.ReactNode[]
  current?: boolean
  image: string | React.ReactNode
  label: string
  path: string
  theme?: 'default' | 'primary' | 'secondary' | 'tertiary'
}

const ListItem = ({
  children = null,
  current = false,
  image,
  label,
  path,
  theme = 'default'
}: ListItemProps) => {
  const _image = typeof image === 'string' ? <img src={image} alt="" /> : image
  return (
    <li
      className={classnames(
        styles.AvatarMenu__list__item,
        styles[`AvatarMenu__list__item--${theme}`]
      )}
      translate="no">
      <a
        href={path}
        className={classnames(styles.AvatarMenu__list__item__link, {
          [styles['AvatarMenu__list__item__link--current']]: current
        })}>
        <span className={styles.AvatarMenu__list__item__icon}>
          {_image ?? label.charAt(0)}
        </span>
        <span className={styles.AvatarMenu__list__item__label}>{label}</span>
      </a>
      {children}
    </li>
  )
}

export default ListItem
