import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { TopItemProps, TopItem } from '../index'
import { PodiumType } from '../../index'
import { Avatar } from 'Elements'
import PodiumStore, { ItemProps } from './Store'
import * as styles from './styles.module.scss'

interface PodiumProps extends TopItemProps {
  route: string
  currentId?: string
  type: string
}

const Podium = observer(
  ({
    currentId,
    route,
    title,
    subTitle,
    linkPath,
    linkLabel,
    number = 3,
    emptyMessage,
    type
  }: PodiumProps) => {
    const { I18n } = useLocalI18n('molecules/Leaderboard/Top/Lang')

    const _computeName = (item: ItemProps) => {
      if (type === PodiumType.PEOPLE) {
        return item.isCurrent
          ? I18n.t('leaderboard.top.you')
          : `${item.firstName} ${item.lastName}`
      }

      return item.name
    }

    useEffect(() => {
      PodiumStore.init(type, currentId)
      void PodiumStore.fetchItems({
        route,
        pageSize: number
      })
    }, [])

    return (
      <TopItem
        className={styles.Podium}
        linkPath={linkPath}
        linkLabel={linkLabel}
        title={title}
        subTitle={subTitle}
        loading={PodiumStore.loading}
        emptyMessage={PodiumStore.items.length === 0 && emptyMessage}>
        {!PodiumStore.loading && PodiumStore.items.length > 0 && (
          <div className={styles.Podium__items}>
            {PodiumStore.items.map((item: ItemProps) => {
              return (
                <div
                  key={`podium-${item.id}`}
                  className={classnames(
                    styles.Podium__item,
                    styles[`Podium__item--${item.rank}`]
                  )}>
                  <div className={styles.Podium__item__avatar}>
                    <Avatar
                      firstName={item.firstName}
                      lastName={item.lastName}
                      imageSrc={item.avatar}
                    />
                  </div>
                  <div className={styles.Podium__item__name} translate="no">
                    {_computeName(item)}
                  </div>
                  <div className={styles.Podium__item__podium}>
                    <div
                      className={styles.Podium__item__podium__step}
                      style={{
                        height: `calc(${item.step} * var(--podium-height)${
                          item.rank !== '1' ? ' - 1rem' : ''
                        })`
                      }}
                    />
                  </div>
                  <div className={styles.Podium__item__points}>
                    {item.score} PTS
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </TopItem>
    )
  }
)

export default Podium
