import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { ModalContainer } from 'Modals'
import ModalStore from 'Stores/ModalStore'
import {
  MessageContainer,
  CommitmentModalContainer,
  FootprintMessageModalContainer
} from 'clarity'

interface ContentProps {
  children: React.ReactNode[] | React.ReactNode
  modalStore: ModalStore
}

const Content = observer(({ children, modalStore }: ContentProps) => (
  <Fragment>
    {children}
    <ModalContainer onClose={modalStore.closeModal} modal={modalStore.modal} />
    <MessageContainer style={{ marginTop: 'var(--translate-top)' }} />
    <CommitmentModalContainer />
    <FootprintMessageModalContainer />
  </Fragment>
))

interface LayoutProps {
  className?: string
  children: React.ReactNode[] | React.ReactNode
  modalStore: ModalStore
  tag?: React.ElementType
}

const Layout = observer(
  ({ children, className = null, modalStore, tag = null }: LayoutProps) => {
    if (tag) {
      const HTMLTag = tag

      return (
        <HTMLTag className={className}>
          <Content modalStore={modalStore}>{children}</Content>
        </HTMLTag>
      )
    }

    return (
      <Fragment>
        <Content modalStore={modalStore}>{children}</Content>
      </Fragment>
    )
  }
)

export default Layout
