import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import classnames from 'classnames'
import * as styles from './styles.module.scss'
import { loadAsOrderedArray } from 'Utilities/locales'

interface LinkedImagesProps {
  slug?: string
  image: string
  label: string
  link?: string
}

interface LinkProps {
  label: string
  link: string
  on_click?: string
  target: string
}

interface FooterProps {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  links?: LinkProps[]
}

const Footer = ({ children, className = '', links }: FooterProps) => {
  const { I18n } = useLocalI18n('elements/Footer/Lang')

  const _onClick = {
    _openBeacon: (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.Beacon) {
        window.Beacon('open')
      } else {
        window.open('https://giki-social-enterprise.helpscoutdocs.com/')
      }
    }
  }

  const _links =
    links ||
    (loadAsOrderedArray('footer.links') as unknown as LinkProps[]) ||
    []
  const _partners: LinkedImagesProps[] =
    (loadAsOrderedArray('footer.partners') as unknown as LinkedImagesProps[]) ||
    []
  const _strapline = I18n.t('footer.strapline')
  const _copyright = I18n.t('footer.copyright', {
    current_year: new Date().getFullYear()
  })

  return (
    <footer className={classnames(styles.Footer, className)}>
      {children && <div className={styles.Footer__actions}>{children}</div>}
      <div className={styles.Footer__container}>
        <div className={styles.Footer__content}>
          <div>
            <div className={styles.Footer__app}>
              <a
                href="https://play.google.com/store/apps/details?id=earth.giki.zero&utm_source=website&utm_campaign=widgets"
                target="_blank"
                rel="noreferrer">
                <img
                  alt="Get it on Google Play"
                  src="/images/google-play.svg"
                />
              </a>
              <a
                href="https://apps.apple.com/app/apple-store/id1615016031?pt=119065048&ct=website&mt=8"
                target="_blank"
                rel="noreferrer">
                <img
                  alt="Download on the App Store"
                  src="/images/app-store.svg"
                />
              </a>
            </div>
            <div className={styles.Footer__partners}>
              {_partners.map((item: LinkedImagesProps, index: number) => {
                if (item.link) {
                  return (
                    <a
                      key={index}
                      className={styles.Footer__partners__item}
                      href={item.link}
                      target="_blank"
                      rel="noreferrer noopener"
                      id={`partnerlogo-${item.slug}`}>
                      <img src={item.image} alt={item.label} />
                    </a>
                  )
                }

                return (
                  <span
                    key={index}
                    className={styles.Footer__partners__item}
                    id={`partnerlogo-${item.slug}`}>
                    <img src={item.image} alt={item.label} />
                  </span>
                )
              })}
            </div>
          </div>
          <div>
            <div className={styles.Footer__links}>
              {_links.map((item: LinkProps, index: number) => (
                <a
                  key={index}
                  href={item.link}
                  onClick={_onClick[item.on_click]}
                  target={item.target}>
                  {item.label}
                </a>
              ))}
            </div>
            <span className={styles.Footer__copyright} translate="no">
              {ReactHtmlParser(_copyright)}
            </span>
            <span className={styles.Footer__strapline}>{_strapline}</span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
