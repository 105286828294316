import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

export interface IndicatorItemProps {
  image: string
  name: string
  onClick: () => void
}

interface IndicatorProps {
  items: IndicatorItemProps[]
  position: 'top' | 'bottom'
}

const Indicator = ({ items, position }: IndicatorProps) => {
  const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

  if (!items) return

  return (
    <div
      className={classnames(styles.Indicator, styles[`Indicator--${position}`])}
      translate="no">
      {items?.map((item, index) => (
        <button
          key={index}
          className={styles.Indicator__button}
          onClick={item.onClick}
          aria-label={I18n.t('leaderboard.list.indicator.label', {
            name: item.name
          })}
          title={I18n.t('leaderboard.list.indicator.label', {
            name: item.name
          })}>
          {item.image ? <img src={item.image} alt="" /> : item.name.charAt(0)}
        </button>
      ))}
    </div>
  )
}

export default Indicator
