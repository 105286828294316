import useLocalI18n from 'Hooks/LocalI18n'
import React from 'react'
import classnames from 'classnames'
import { DataItem, Avatar } from 'Elements'
import Change from '../Change'
import * as styles from './styles.module.scss'

export interface ListItemProps {
  rank: number
  avatar: string
  firstName?: string
  lastName?: string
  name?: string
  team?: string
  score: number
  successfulCommitmentCount: number
  pendingCommitmentCount: number
  change: number
  hasHighScore?: boolean
  isCurrent?: boolean
  isCurrentOrganization?: boolean
  isCurrentTeam?: boolean
  sortedBy: string
}

const ListItem = ({
  rank,
  avatar,
  firstName,
  lastName,
  name,
  team,
  score,
  successfulCommitmentCount,
  pendingCommitmentCount,
  change,
  hasHighScore = false,
  isCurrent = false,
  isCurrentOrganization = false,
  isCurrentTeam = false,
  sortedBy = 'score'
}: ListItemProps) => {
  const { I18n } = useLocalI18n('molecules/Leaderboard/List/Lang')

  const scoreClassNames = [
    styles.ListItem__score,
    {
      [styles['ListItem__column--active']]: sortedBy === 'score'
    }
  ]

  const changeClassNames = [
    styles.ListItem__change,
    {
      [styles['ListItem__column--active']]: sortedBy === 'change'
    }
  ]

  return (
    <li
      className={classnames(styles.ListItem, {
        [styles['ListItem--current']]:
          isCurrent || isCurrentTeam || isCurrentOrganization
      })}
      translate="no">
      <div className={styles.ListItem__rank}>{rank}</div>
      <div
        className={classnames(styles.ListItem__avatar, {
          [styles['ListItem__avatar--crown']]: hasHighScore
        })}>
        <div className={styles.ListItem__avatar__image}>
          <Avatar
            imageSrc={avatar}
            firstName={firstName ?? name}
            lastName={lastName}
          />
        </div>
      </div>
      <div className={styles.ListItem__namegroup}>
        <div className={styles.ListItem__name}>
          {isCurrent ? I18n.t('leaderboard.list.you') : name}
          {isCurrentTeam && (
            <div className={styles.ListItem__name__sub}>
              {I18n.t('leaderboard.list.your_team')}
            </div>
          )}
          {isCurrentOrganization && (
            <div className={styles.ListItem__name__sub}>
              {I18n.t('leaderboard.list.your_organization')}
            </div>
          )}
          {team && <div className={styles.ListItem__name__sub}>{team}</div>}
        </div>
        <div className={styles.ListItem__dataitemgroup}>
          <div className={styles.ListItem__dataitem}>
            <DataItem
              type="actions_pending"
              value={pendingCommitmentCount}
              micro
            />
          </div>
          <div className={styles.ListItem__dataitem}>
            <DataItem
              type="actions_complete"
              value={successfulCommitmentCount}
              micro
            />
          </div>
        </div>
      </div>
      <div className={classnames(scoreClassNames)}>
        <span className={styles.ListItem__score__value}>
          {I18n.toNumber(score, { precision: 0 })}
        </span>
        <span className={styles.ListItem__score__unit}>PTS</span>
      </div>
      <div className={classnames(changeClassNames)}>
        <Change value={change} />
      </div>
    </li>
  )
}

export default ListItem
